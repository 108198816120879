"use client";

import React, { useState, useEffect, useRef } from "react";
import { Clock } from "lucide-react";

interface TimePickerProps {
  value: string;
  onChange: (time: string) => void;
  error?: string;
}

const CustomTimePicker: React.FC<TimePickerProps> = ({ value, onChange, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState("09");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [period, setPeriod] = useState("AM");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const convertTo24Hour = (hour: number, meridian: string): number => {
    if (meridian === "AM") {
      return hour === 12 ? 0 : hour;
    }
    return hour === 12 ? 12 : hour + 12;
  };

  const convertTo12Hour = (hour24: number): { hour12: number; period: string } => {
    if (hour24 === 0) {
      return { hour12: 12, period: "AM" };
    } else if (hour24 === 12) {
      return { hour12: 12, period: "PM" };
    } else if (hour24 > 12) {
      return { hour12: hour24 - 12, period: "PM" };
    } else {
      return { hour12: hour24, period: "AM" };
    }
  };

  const isValidOfficeHour = (hour: string, meridian: string): boolean => {
    const hour24 = convertTo24Hour(parseInt(hour), meridian);
    return hour24 >= 9 && hour24 <= 18; // 9 AM to 6 PM
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (value) {
      const [time, meridian] = value.split(" ");
      const [hour, minute] = time.split(":");
      setSelectedHour(hour);
      setSelectedMinute(minute);
      setPeriod(meridian);
    }
  }, [value]);

  const hours = Array.from({ length: 12 }, (_, i) => {
    const hour = i + 1;
    return String(hour).padStart(2, "0");
  });

  const minutes = Array.from({ length: 12 }, (_, i) => String(i * 5).padStart(2, "0"));

  const handleTimeSelection = (hour: string, minute: string, meridian: string) => {
    const formattedTime = `${hour}:${minute} ${meridian}`;
    onChange(formattedTime);
  };

  const handleFieldClick = () => {
    setIsOpen(!isOpen);
  };

  const handleHourClick = (hour: string) => {
    setSelectedHour(hour);
    handleTimeSelection(hour, selectedMinute, period);
  };

  const handleMinuteClick = (minute: string) => {
    setSelectedMinute(minute);
    handleTimeSelection(selectedHour, minute, period);
  };

  const handlePeriodClick = (newPeriod: string) => {
    setPeriod(newPeriod);
    handleTimeSelection(selectedHour, selectedMinute, newPeriod);
  };

  const isDisabledHour = (hour: string): boolean => {
    return !isValidOfficeHour(hour, period);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className={`inter text-sm flex cursor-pointer items-center justify-between rounded-xl bg-secondary-light px-3 py-4`}
        onClick={handleFieldClick}
      >
        <span className={`${value ? "text-gray-900" : "text-gray-500"}`}>
          {value || "Select Time (9:00 AM - 6:00 PM)"}
        </span>
        <Clock className="h-6 w-6 text-gray-400" />
      </div>

      {error && <p className="mt-1 text-xs text-red-500">{error}</p>}

      {isOpen && (
        <div className="absolute -top-28 lg:top-full z-50 mt-2 w-[280px] rounded-xl bg-white p-4 shadow-lg">
          <div className="mb-4 flex justify-between">
            <div className="text-xl font-medium">{`${selectedHour}:${selectedMinute} ${period}`}</div>
            <div className="space-x-2">
              <button
                type="button"
                className={`rounded px-2 py-1 transition-colors ${period === "AM" ? "bg-primary text-white" : "bg-gray-100 hover:bg-gray-200"}`}
                onClick={() => handlePeriodClick("AM")}
              >
                AM
              </button>
              <button
                type="button"
                className={`rounded px-2 py-1 transition-colors ${period === "PM" ? "bg-primary text-white" : "bg-gray-100 hover:bg-gray-200"}`}
                onClick={() => handlePeriodClick("PM")}
              >
                PM
              </button>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <div className="mb-2 text-sm font-medium text-gray-500">Hour</div>
              <div className="grid grid-cols-3 gap-2">
                {hours.map((hour) => (
                  <button
                    type="button"
                    key={hour}
                    className={`rounded p-2 text-sm transition-colors ${
                      hour === selectedHour ? "bg-primary text-white" : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleHourClick(hour)}
                  >
                    {hour}
                  </button>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <div className="mb-2 text-sm font-medium text-gray-500">Minute</div>
              <div className="grid grid-cols-3 gap-2">
                {minutes.map((minute) => (
                  <button
                    type="button"
                    key={minute}
                    className={`rounded p-2 text-sm transition-colors ${
                      minute === selectedMinute ? "bg-primary text-white" : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleMinuteClick(minute)}
                  >
                    {minute}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-4 text-xs text-gray-500">Business hours: 9:00 AM - 6:00 PM</div>
        </div>
      )}
    </div>
  );
};

export default CustomTimePicker;
