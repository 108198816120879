"use client";

import { Calendar, ChevronLeft, ChevronRight } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";

interface DatePickerProps {
  onDateSelect?: (date: Date) => void;
  initialDate?: Date;
  placeholder?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateSelect,
  initialDate = new Date(),
  placeholder = "Preferred Date",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(initialDate);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from({ length: 10 }, (_, i) => currentDate.getFullYear() - 5 + i);
  const daysOfWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setIsYearDropdownOpen(false);
        setIsMonthDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();

    const adjustedFirstDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
    const days = [];

    for (let i = 0; i < adjustedFirstDay; i++) {
      const prevMonthDay = new Date(year, month, 0 - (adjustedFirstDay - 1 - i));
      days.push({
        date: prevMonthDay,
        isCurrentMonth: false,
      });
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push({
        date: new Date(year, month, i),
        isCurrentMonth: true,
      });
    }

    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      days.push({
        date: new Date(year, month + 1, i),
        isCurrentMonth: false,
      });
    }

    return days;
  };

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    onDateSelect?.(date);
    setIsOpen(false);
  };

  const handleYearSelect = (year: number) => {
    setCurrentDate(new Date(year, currentDate.getMonth(), 1));
    setIsYearDropdownOpen(false);
  };

  const handleMonthSelect = (monthIndex: number) => {
    setCurrentDate(new Date(currentDate.getFullYear(), monthIndex, 1));
    setIsMonthDropdownOpen(false);
  };

  const isToday = (date: Date) => {
    const today = new Date();
    return date.toDateString() === today.toDateString();
  };

  const isSelected = (date: Date) => {
    return selectedDate?.toDateString() === date.toDateString();
  };

  const formatDate = (date: Date | null) => {
    if (!date) return "";
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Input Field */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="inter flex w-full items-center justify-between rounded-xl bg-secondary-light px-3 py-4 text-sm text-secondary-foreground placeholder:text-secondary-foreground"
        tabIndex={0}
      >
        <span className={selectedDate ? "text-gray-900" : "text-gray-500"}>
          {selectedDate ? formatDate(selectedDate) : placeholder}
        </span>
        <Calendar className="h-6 w-6 text-gray-400" />
      </div>

      {/* Date Picker Modal */}
      {isOpen && (
        <div className="absolute -top-28 z-50 mt-2 w-72 rounded-lg bg-white p-4 shadow-lg lg:top-full">
          <div className="mb-4 flex items-center justify-between">
            <button onClick={handlePrevMonth} className="rounded-full p-1 hover:bg-gray-100">
              <ChevronLeft className="h-5 w-5 text-gray-600" />
            </button>

            <div className="flex items-center space-x-2">
              {/* Month Dropdown */}
              <div className="relative">
                <button
                  onClick={() => setIsMonthDropdownOpen(!isMonthDropdownOpen)}
                  className="rounded px-2 py-1 text-gray-800 hover:bg-gray-100"
                >
                  {months[currentDate.getMonth()]}
                </button>
                {isMonthDropdownOpen && (
                  <div className="absolute top-full mt-1 max-h-48 w-32 overflow-y-auto rounded-lg bg-white py-1 shadow-lg">
                    {months.map((month, index) => (
                      <button
                        key={month}
                        onClick={() => handleMonthSelect(index)}
                        className="w-full px-3 py-1 text-left hover:bg-gray-100"
                      >
                        {month}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              {/* Year Dropdown */}
              <div className="relative">
                <button
                  onClick={() => setIsYearDropdownOpen(!isYearDropdownOpen)}
                  className="rounded px-2 py-1 text-gray-800 hover:bg-gray-100"
                >
                  {currentDate.getFullYear()}
                </button>
                {isYearDropdownOpen && (
                  <div className="absolute top-full mt-1 max-h-48 w-20 overflow-y-auto rounded-lg bg-white py-1 shadow-lg">
                    {years.map((year) => (
                      <button
                        key={year}
                        onClick={() => handleYearSelect(year)}
                        className="w-full px-3 py-1 text-left hover:bg-gray-100"
                      >
                        {year}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <button onClick={handleNextMonth} className="rounded-full p-1 hover:bg-gray-100">
              <ChevronRight className="h-5 w-5 text-gray-600" />
            </button>
          </div>

          <div className="grid grid-cols-7 gap-1">
            {daysOfWeek.map((day) => (
              <div key={day} className="py-2 text-center text-sm font-medium text-gray-600">
                {day}
              </div>
            ))}

            {getDaysInMonth(currentDate).map((dayObj, index) => (
              <button
                key={index}
                onClick={() => handleDateSelect(dayObj.date)}
                className={`rounded-lg p-2 text-sm ${dayObj.isCurrentMonth ? "hover:bg-gray-100" : "text-gray-400"} ${isToday(dayObj.date) ? "bg-gray-100 text-primary" : ""} ${isSelected(dayObj.date) ? "bg-primary text-white hover:bg-gray-100" : ""} ${!dayObj.isCurrentMonth && !isSelected(dayObj.date) ? "hover:bg-gray-50" : ""} `}
              >
                {dayObj.date.getDate()}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
