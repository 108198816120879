import dynamic from "next/dynamic";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import CustomDropdown from "./CustomDropdown";
import FormPhoneField from "@/components/common/forms/FormPhoneField";
import CustomTimePicker from "@/components/ui/CustomTimePicker";
import DatePicker from "@/components/ui/DatePicker";
import { useAuth } from "@/contexts/AuthContext";
import { createShowroomVisitRequest } from "@/lib/api/visit-showroom/create-visit-showrrom-request";
import { convertTo24Hour } from "@/lib/utils";
import successAnimation from "@/public/assets/animations/success.json";

// Dynamically import Lottie with SSR disabled
const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

export interface ShowroomVisitData {
  Visitor_FullName: string;
  Visitor_Mobile: string;
  Visit_Date: string;
  Visit_Time: string;
  Visit_Interest: "sell my car" | "car viewing";
}

interface ShowroomVisitFormProps {
  title?: string;
  subTitle?: string;
  onSuccess?: () => void;
}

const ShowroomVisitForm: React.FC<ShowroomVisitFormProps> = ({
  title = "Get in touch",
  subTitle = "Visit our Showroom and Experience the Car of your dreams",
  onSuccess,
}) => {
  const { isAuthLoading, isAuthenticated, profile } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  let successTimeout: NodeJS.Timeout | null = null;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    trigger,
  } = useForm<ShowroomVisitData>({
    defaultValues: {
      Visit_Interest: "car viewing",
    },
    mode: "onTouched",
  });

  const resetForm = useCallback(() => {
    reset({
      Visitor_FullName: isAuthenticated && profile ? profile.name || "" : "",
      Visitor_Mobile: isAuthenticated && profile ? profile.phone || "" : "",
      Visit_Date: "",
      Visit_Time: "",
      Visit_Interest: "car viewing",
    });
  }, [isAuthenticated, reset, profile]);

  useEffect(() => {
    if (isAuthenticated && profile) {
      resetForm();
    }
  }, [isAuthenticated, profile, resetForm]);

  const onSubmit = async (data: ShowroomVisitData) => {
    try {
      setIsLoading(true);
      data.Visit_Time = convertTo24Hour(data.Visit_Time);
      await createShowroomVisitRequest(data);

      setShowSuccess(true);
      resetForm();
      onSuccess?.();

      successTimeout = setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect to handle cleanup
  useEffect(() => {
    return () => {
      if (successTimeout) {
        clearTimeout(successTimeout);
      }
    };
  }, [successTimeout]);

  const interestOptions = ["Car Viewing", "Sell My Car"];

  if (showSuccess) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <div className="h-64 w-64">
          <Lottie animationData={successAnimation} loop={false} autoplay />
        </div>
        <p className="mt-4 text-center text-lg font-semibold text-green-600">Request sent successfully!</p>
      </div>
    );
  }

  return (
    <>
      <h4 className="urbanist text-2xl font-semibold text-black lg:text-3xl">{title}</h4>
      <p className="inter text-sm text-[#696969]">{subTitle}</p>

      <div className="mt-5">
        <div className="mb-4">
          <input
            {...register("Visitor_FullName", {
              required: "Full name is required",
            })}
            className="inter w-full rounded-xl bg-secondary-light px-3 py-4 text-sm font-medium text-secondary-foreground placeholder:text-secondary-foreground"
            placeholder={isAuthLoading ? "Loading user information..." : "Full Name"}
            disabled={isAuthLoading || isAuthenticated}
          />
          {errors.Visitor_FullName && <p className="mt-1 text-xs text-red-500">{errors.Visitor_FullName.message}</p>}
        </div>

        <div className="mb-4">
          <FormPhoneField
            name="Visitor_Mobile"
            control={control}
            placeholder={isAuthLoading ? "Loading user information..." : "Phone Number"}
            disabled={isAuthLoading || isAuthenticated}
          />
          {errors.Visitor_Mobile && <p className="mt-1 text-xs text-red-500">{errors.Visitor_Mobile.message}</p>}
        </div>

        <div className="mb-4">
          <Controller
            name="Visit_Date"
            control={control}
            rules={{
              required: "Please select a date",
              validate: (value) => {
                if (value && new Date(value) < new Date()) {
                  return "Date must be in the future";
                }
                return true;
              },
            }}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                initialDate={value ? new Date(value) : new Date()}
                onDateSelect={(date) => {
                  const dateString = date.toISOString();
                  if (dateString !== value) {
                    onChange(dateString);
                    trigger("Visit_Date");
                  }
                }}
              />
            )}
          />
          {errors.Visit_Date && <p className="mt-1 text-xs text-red-500">{errors.Visit_Date.message}</p>}
        </div>

        <div className="mb-4">
          <Controller
            name="Visit_Time"
            control={control}
            rules={{
              required: "Please select a time",
              validate: (value) => {
                const [timePart, period] = value.split(" ");
                const [hours, minutes] = timePart.split(":");
                let hour = parseInt(hours);
                if (period === "PM" && hour !== 12) {
                  hour += 12;
                } else if (period === "AM" && hour === 12) {
                  hour = 0;
                }
                return hour >= 9 && hour <= 17
                  ? true
                  : "Please select a time within business hours (9:00 AM to 5:59 PM)";
              },
            }}
            render={({ field: { value, onChange } }) => (
              <CustomTimePicker
                value={value}
                onChange={(time) => {
                  onChange(time);
                  trigger("Visit_Time");
                }}
                error={errors.Visit_Time ? errors.Visit_Time?.message : undefined}
              />
            )}
          />
        </div>

        <div className="mb-4">
          <Controller
            name="Visit_Interest"
            control={control}
            rules={{
              required: "Please select a time",
            }}
            render={({ field: { value, onChange } }) => (
              <CustomDropdown placeholder="Visit Interest" options={interestOptions} onChange={onChange} />
            )}
          />
          {errors.Visit_Interest && <p className="mt-1 text-xs text-red-500">{errors.Visit_Interest.message}</p>}
        </div>

        <button
          type="button"
          className="inter relative w-full rounded-2xl bg-primary py-3 text-center font-semibold text-sm text-white disabled:opacity-70 transition-all duration-300 hover:bg-primary-dark"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <span className="opacity-0">Book your Visit</span>
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent" />
              </div>
            </>
          ) : (
            "Book your Visit"
          )}
        </button>
      </div>
    </>
  );
};

export default ShowroomVisitForm;
