"use client";

import React, { useEffect, useRef, useState } from "react";
import { ChevronDown } from "lucide-react";

interface CustomDropdownProps {
  options: string[];
  placeholder?: string;
  onChange?: (value: string) => void;
}

const CustomDropdown = ({ options, placeholder = "Select an option", onChange }: CustomDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange?.(option);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="inter text-sm flex items-center w-full cursor-pointer items-center justify-between rounded-xl bg-secondary-light px-3 py-4"
      >
        <span className={selectedOption ? "text-gray-900" : "text-gray-500"}>{selectedOption || placeholder}</span>
        <ChevronDown className="h-6 w-6 text-gray-400" />
      </div>

      {isOpen && (
        <div className="absolute top-10 z-10 mt-1 w-full lg:top-full rounded-lg border border-gray-200 bg-white shadow-lg">
          <ul className="max-h-60 overflow-auto py-1">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleSelect(option)}
                className="cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-50"
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
